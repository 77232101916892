import React, { useState } from "react";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import Button from "../../../components/custom-widgets/button";
import CardGeneric from "../../../components/card/card-generic";
import OnlineEnrollentModal from "../../../components/custom-widgets/online-enroll-modal";
import logo from "../../../images/favicons/favicon-32x32.png";
import styles from "../../../pages/commercial-banking/treasury-management-services/express.module.scss";

const Login = () => {
  const seoData = {
    title: "Ingresa a tu banca personal en línea",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Ingresa a tu banca personal en línea"
      },
      {
        name: "description",
        content:
          "Con la banca en línea de WaFd Bank realice todas las operaciones que necesita. Aproveche todos los servicios de banca personal en línea que tenemos para usted."
      },
      {
        property: "og:title",
        content: "Ingresa a tu banca personal en línea"
      },
      {
        property: "og:description",
        content:
          "Con la banca en línea de WaFd Bank realice todas las operaciones que necesita. Aproveche todos los servicios de banca personal en línea que tenemos para usted."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/entrar"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-signin-02-250.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca personal"
    },
    {
      id: 2,
      active: true,
      title: "Iniciar sesión en cuenta personal"
    }
  ];

  const signInBtn = {
    id: "personal-banking-es-sign-in-button",
    class: "btn-access-green btn-block no-min-width no-max-width olb-sign-in-link",
    text: "Conéctese",
    showIcon: false,
    url: "https://online.wafdbank.com"
  };

  const [showEnrollModal, setShowEnrollModal] = useState(false);
  const handleCloseEnrollModal = () => setShowEnrollModal(false);
  const handleShowEnrollModal = (e) => {
    e.preventDefault();
    setShowEnrollModal(true);
  };

  const enrollModal = {
    id: "online-enroll-modal",
    handleClose: handleCloseEnrollModal,
    show: showEnrollModal
  };

  const personalCardData = {
    cardClass: "h-md-100 border-0",
    segments: [
      {
        type: "card-header",
        content: [
          <div className="mb-3">
            <h4>Cuentas Personales</h4>
            <p>Conéctese a su sesión para manejar su cuenta de cheques y ahorros de WaFd Bank.</p>
          </div>
        ],
        class: "border-0 p-0 bg-light"
      },
      {
        type: "card-header",
        content: [
          <div className="row">
            <div className="col-2">
              <img src={logo} alt="WaFd Bank logo" />
            </div>

            <div className="col-10">
              <p className="mb-0 pt-1">Banca Personal en Línea</p>
            </div>
          </div>
        ],
        class: "bg-white border-bottom-3 border-success"
      },
      {
        type: "card-body",
        content: [<Button {...signInBtn} />]
      },
      {
        type: "card-footer",
        content: [
          <div className="text-center">
            <button className={`text-primary ${styles.buttonTransparent}`} onClick={handleShowEnrollModal}>
              Registrar para cuenta en linea
            </button>
          </div>
        ],
        class: "bg-white"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <Breadcrumb data={breadcrumbData} />
      <SEO {...seoData} />
      {showEnrollModal && <OnlineEnrollentModal {...enrollModal} />}

      <section className="container">
        <div className="row">
          <div className="col-lg-6 col-xl-7">
            <h1>Banca Personal en Línea</h1>

            <p>Bienvenido a la página de sesión en línea de WaFd Bank.</p>
          </div>

          <div className="col-lg-6 col-xl-5">
            <div className="bg-light p-5">
              <CardGeneric {...personalCardData} />
            </div>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default Login;
